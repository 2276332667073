import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../images/TransLogo.png";
import "../components/NavMenu.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import constitution from "../docs/LiRRA_Constitution.pdf";
import submission from "../docs/SKMBT_C22024070210180.pdf";
import kiambu from "../docs/SKMBT_C22024070210051.pdf";

function NavMenu() {
  const auth = getAuth();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = React.useState(false);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const menuIconRef = useRef();
  const menuRef = useRef();
  const menuItemRef = useRef();

  const toggleMenu = () => {
    setShowMenu((prevState) => !prevState);
  };

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
    setShowMenu(false);
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        showMenu &&
        !menuRef.current.contains(event.target) &&
        !menuIconRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }

      if (
        showDropdown &&
        !menuItemRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    const handleScroll = () => {
      if (window.scrollY > 50) {
        menuRef.current.classList.add("sticky");
      } else {
        menuRef.current.classList.remove("sticky");
      }
    };

    document.addEventListener("click", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showMenu, showDropdown]);

  const handleLogout = () => {
    auth.signOut().then(() => {
      toast.success("Logout successful", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      navigate("/");
    });
  };

  return (
    <nav className="navbar" ref={menuRef}>
      <div className="navbar-logo">
        <Link to="/">
          <img src={logo} alt="LinkRoad Logo" />
        </Link>
      </div>
      <div className="menu-icon" ref={menuIconRef} onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </div>
      <ToastContainer />
      <ul className={`navbar-menu${showMenu ? " show" : ""}`} ref={menuItemRef}>
        <li className="navbar-item" onClick={handleLinkClick}>
          <Link to="/" className="navbar-link">HOME</Link>
        </li>
        <li className="navbar-item" onClick={handleLinkClick}>
          <Link to="/about" className="navbar-link">ABOUT</Link>
        </li>
        <li className="navbar-item documents-link-container" onClick={toggleDropdown}>
          <div className="navbar-link documents-link">DOCUMENTS</div>
          {showDropdown && (
            <ul className="dropdown-menu">
              <li className="navbar-item">
                <a href={constitution} download className="navbar-link">The Constitution</a>
              </li>
              <li className="navbar-item">
                <a href={submission} download className="navbar-link">Our previous Submission on Kikuyu Integrated Strategic Urban Development Plan 2020-2030</a>
              </li>
              <li className="navbar-item">
                <a href={kiambu} download className="navbar-link">Our previous Submission on Kiambu County Integrated Development Plan (CIDP) 2023 - 2027</a>
              </li>
            </ul>
          )}
        </li>
        <li className="navbar-item" onClick={handleLinkClick}>
          <Link to="/area-of-operation" className="navbar-link">AREA MAPS</Link>
        </li>
        <li className="navbar-item" onClick={handleLinkClick}>
          <Link to="/services" className="navbar-link">MERCHANDISE</Link>
        </li>
        <li className="navbar-item" onClick={handleLinkClick}>
          <a href="#contact" className="navbar-link">CONTACT</a>
        </li>
        <li className="navbar-item" onClick={handleLinkClick}>
          <Link to="/registers" className="navbar-link">REGISTER</Link>
        </li>
        {user ? (
          <>
            <li className="navbar-item" onClick={handleLinkClick}>
              <Link to="/profile" className="navbar-link">PROFILE</Link>
            </li>
            <li className="navbar-item" onClick={handleLinkClick}>
              <a href="#logout" className="navbar-link" onClick={handleLogout}>LOGOUT</a>
            </li>
          </>
        ) : null}
      </ul>
    </nav>
  );
}

export default NavMenu;
