import React from 'react';
import './AreaOfOperation.css'; // Ensure to import the CSS file

const AreaOfOperation = () => {
  return (
    <div className="area-of-operation">
      <h2>Area of Operation</h2>
      <div className="maps-container">
        {/* Embedding Google My Maps iframe */}
        <iframe
          title="Area of Operation"
          src="https://www.google.com/maps/d/u/0/embed?mid=1S34yAxTZRZ0dy9XfcydJ79xDquFNxAM&ehbc=2E312F&noprof=1"
          className="responsive-iframe"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
      </div>

      <h2>Zone Sections</h2>
      <div className="maps-container">
        {/* Embedding Google My Maps iframe */}
        <iframe
          title="Area of Operation"
          src="https://www.google.com/maps/d/u/0/embed?mid=145k-aHLHmZDx7FSZnya1wzoZDSY0PTY&ehbc=2E312F&noprof=1"
          className="responsive-iframe"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
      </div>

      <h2>Kikuyu Link Road(F2013)</h2>
      <div className="maps-container">
        {/* Embedding Google My Maps iframe */}
        <iframe
          title="Area of Operation"
          src="https://www.google.com/maps/d/u/0/embed?mid=1ukAFd0COPAp9FdDJtTpNkPnt25Mcn74&ehbc=2E312F&noprof=1"
          className="responsive-iframe"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
      </div>

      <h2>Link Road Residents Association Drives</h2>
      <div className="maps-container">
        {/* Embedding Google My Maps iframe */}
        <iframe
          title="Area of Operation"
          src="https://www.google.com/maps/d/u/0/embed?mid=1ivND9z64yV0sY78r2WG62UOsVdUEL6g&ehbc=2E312F&noprof=1"
          className="responsive-iframe"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default AreaOfOperation;
